import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import './ProjectRoadmap.css';
import { useTranslation } from 'react-i18next';

export const ProjectRoadmap = () => {
  const { t } = useTranslation();
  const [activeCard, setActiveCard] = useState(null);

  const timelineData = [
    {
      id: 1,
      date: t('roadmap.timeline.0.date'),
      title: t('roadmap.timeline.0.title'),
      content: [
        t('roadmap.timeline.0.content.0'),
        t('roadmap.timeline.0.content.1'),
        t('roadmap.timeline.0.content.2'),
        t('roadmap.timeline.0.content.3'),
        t('roadmap.timeline.0.content.4')
      ]
    },
    {
      id: 2,
      date: t('roadmap.timeline.1.date'),
      title: t('roadmap.timeline.1.title'),
      content: [
        t('roadmap.timeline.1.content.0'),
        t('roadmap.timeline.1.content.1'),
        t('roadmap.timeline.1.content.2'),
        t('roadmap.timeline.1.content.3')
      ]
    },
    {
      id: 3,
      date: t('roadmap.timeline.2.date'),
      title: t('roadmap.timeline.2.title'),
      content: [
        t('roadmap.timeline.2.content.0'),
        t('roadmap.timeline.2.content.1'),
        t('roadmap.timeline.2.content.2'),
        t('roadmap.timeline.2.content.3')
      ]
    },
    {
      id: 4,
      date: t('roadmap.timeline.3.date'),
      title: t('roadmap.timeline.3.title'),
      content: [
        t('roadmap.timeline.3.content.0'),
        t('roadmap.timeline.3.content.1'),
        t('roadmap.timeline.3.content.2'),
        t('roadmap.timeline.3.content.3')
      ]
    },
    {
      id: 5,
      date: t('roadmap.timeline.4.date'),
      title: t('roadmap.timeline.4.title'),
      content: [
        t('roadmap.timeline.4.content.0'),
        t('roadmap.timeline.4.content.1'),
        t('roadmap.timeline.4.content.2')
      ]
    },
    {
      id: 6,
      date: t('roadmap.timeline.5.date'),
      title: t('roadmap.timeline.5.title'),
      content: [
        t('roadmap.timeline.5.content.0'),
        t('roadmap.timeline.5.content.1'),
        t('roadmap.timeline.5.content.2')
      ]
    },
    {
      id: 7,
      date: t('roadmap.timeline.6.date'),
      title: t('roadmap.timeline.6.title'),
      content: [
        t('roadmap.timeline.6.content.0'),
        t('roadmap.timeline.6.content.1'),
        t('roadmap.timeline.6.content.2')
      ]
    },
    {
      id: 8,
      date: t('roadmap.timeline.7.date'),
      title: t('roadmap.timeline.7.title'),
      content: [
        t('roadmap.timeline.7.content.0'),
        t('roadmap.timeline.7.content.1'),
        t('roadmap.timeline.7.content.2')
      ]
    }
  ];
  

  const toggleCard = (id) => {
    if (activeCard === id) {
      setActiveCard(null);
    } else {
      setActiveCard(id);
    }
  };

  return (
    <section className="roadmap" id="roadmap">
      <Container>
        <div className="skill-bx wow zoomIn">
          <Row>
            <Col>
              <h2>{t('roadmap.title')}</h2>
              <p>{t('roadmap.subtitle')}</p>
              
              <div className="timeline-container">
                {timelineData.map((item, index) => (
                  <div 
                    key={item.id} 
                    className={`timeline-item ${item.id % 2 === 0 ? 'right' : 'left'} ${
                      index === 3 ? 'current' : ''
                    }`}
                  >
                    <div className="timeline-dot"></div>
                    <div className="timeline-date">{item.date}</div>
                    <div 
                      className={`timeline-card ${activeCard === item.id ? 'active' : ''}`}
                      onClick={() => toggleCard(item.id)}
                    >
                      <h3>{item.title}</h3>
                      <div className="timeline-content">
                        <ul>
                          {item.content.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}; 