import { Row, Col, Container } from "react-bootstrap";
import 'animate.css';
import Duru from '../assets/img/Duru.png';
import Cengiz from '../assets/img/Cengiz.png';
import Ebru from '../assets/img/Ebru.png';
import { FaLinkedin, FaGithub, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const LinkedInIcon = () => <FaLinkedin />;
const GitHubIcon = () => <FaGithub />;
const WebsiteIcon = () => <FaGlobe />;

export const WhoAreWe = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  
  // This effect will run whenever the language changes
  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);
  
  // Recreate the team array when language changes
  const team = [
    {
      f: t('whoarewe.cofounder'),
      name: "Mehmet Cengizhan Kınay",
      role: t('whoarewe.team.0.role'),
      description: t('whoarewe.team.0.description'),
      imageUrl: Cengiz,
      linkedin: "https://www.linkedin.com/in/thingizkhan-kinik-a59653174/",
      github: "https://github.com/thingizkhan"
    },
    {
      f: t('whoarewe.cofounder'),
      name: "Duru Nef Özmen",
      role: t('whoarewe.team.1.role'),
      description: t('whoarewe.team.1.description'),
      imageUrl: Duru,
      linkedin: "https://www.linkedin.com/in/durunef/",
      github: "https://github.com/durunef"
    },
    {
      f: t('whoarewe.cofounder'),
      name: "Ebru Bellek Karakaş",
      role: t('whoarewe.team.2.role'),
      description: t('whoarewe.team.2.description'),
      imageUrl: Ebru,
      linkedin: "https://www.linkedin.com/in/ebru-bellek-karakaş-2ab50a4/",
      website: "https://www.ebrubellek.com"
    }
  ];

  return (
    <section className="who-are-we" id="who-are-we">
      <Container>
        <div className="skill-bx wow zoomIn">
          <Row>
            <Col>
              <h2>{t('whoarewe.title')}</h2>
            </Col>
          </Row>
          <Row>
            {team.map((member, index) => (
              <Col lg={4} md={6} key={index + '-' + currentLanguage}>
                <div className="who-are-we-box">
                  <div className="social-icons-vertical">
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                      <LinkedInIcon />
                    </a>
                    {member.github && (
                      <a href={member.github} target="_blank" rel="noopener noreferrer" className="social-icon">
                        <GitHubIcon />
                      </a>
                    )}
                    {member.website && (
                      <a href={member.website} target="_blank" rel="noopener noreferrer" className="social-icon">
                        <WebsiteIcon />
                      </a>
                    )}
                  </div>
                  <img src={member.imageUrl} alt={member.name} />
                  <h3>{member.f}</h3>
                  <h3>{member.name}</h3>
                  <h4>{member.role}</h4>
                  <p>{member.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
}
