import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-switcher">
      <select 
        className="language-select" 
        value={i18n.language} 
        onChange={changeLanguage}
        aria-label="Language Selector"
      >
        <option value="en">{t('language.en')}</option>
        <option value="tr">{t('language.tr')}</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher; 