import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export const Newsletter = ({ status, message, onValidated }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(false);
  const emailAddress = "info@drmedkit.com";

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const clearFields = () => {
    setEmail('');
  }

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>{t('footer.contact')}</h3>
            <p></p>
            {status === 'sending' && <Alert>Sending...</Alert>}
            {status === 'error' && <Alert variant="danger">{message}</Alert>}
            {status === 'success' && <Alert variant="success">{message}</Alert>}
            <div className="social-buttons">
              <a 
                href={`mailto:${emailAddress}`}
                className="newsletter-button"
                onClick={handleClick}>
                {emailAddress}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  )
}
