import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartPulse,
  faHandsHolding,
  faMobileScreen,
  faShieldHalved,
  faBrain,
  faClockRotateLeft,
  faEarthAmericas,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';
import './WhyChoose.css';

export const WhyChoose = () => {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("forPatients");

  // Reset selected category when language changes to ensure proper rendering
  useEffect(() => {
    setSelectedCategory("forPatients");
  }, [i18n.language]);

  // Define benefit sections for patients with icons
  const patientBenefits = [
    {
      icon: faHeartPulse,
      title: t('whyChoose.personalizedHealthcare.title'),
      points: [
        t('whyChoose.personalizedHealthcare.point1'),
        t('whyChoose.personalizedHealthcare.point2')
      ]
    },
    {
      icon: faHandsHolding,
      title: t('whyChoose.empathyMeetsExpertise.title'),
      points: [
        t('whyChoose.empathyMeetsExpertise.point1'),
        t('whyChoose.empathyMeetsExpertise.point2')
      ]
    },
    {
      icon: faMobileScreen,
      title: t('whyChoose.convenienceAtItsBest.title'),
      points: [
        t('whyChoose.convenienceAtItsBest.point1'),
        t('whyChoose.convenienceAtItsBest.point2'),
        t('whyChoose.convenienceAtItsBest.point3')
      ]
    },
    {
      icon: faShieldHalved,
      title: t('whyChoose.exclusiveFeatures.title'),
      points: [
        t('whyChoose.exclusiveFeatures.point1'),
        t('whyChoose.exclusiveFeatures.point2'),
        t('whyChoose.exclusiveFeatures.point3')
      ]
    }
  ];

  // Define benefit sections for professionals with icons
  const professionalBenefits = [
    {
      icon: faBrain,
      title: t('whyChoose.augmentYourExpertise.title'),
      points: [
        t('whyChoose.augmentYourExpertise.point1'),
        t('whyChoose.augmentYourExpertise.point2')
      ]
    },
    {
      icon: faClockRotateLeft,
      title: t('whyChoose.efficientWorkflow.title'),
      points: [
        t('whyChoose.efficientWorkflow.point1'),
        t('whyChoose.efficientWorkflow.point2')
      ]
    },
    {
      icon: faEarthAmericas,
      title: t('whyChoose.expandYourReach.title'),
      points: [
        t('whyChoose.expandYourReach.point1'),
        t('whyChoose.expandYourReach.point2'),
        t('whyChoose.expandYourReach.point3')
      ]
    },
    {
      icon: faChartLine,
      title: t('whyChoose.additionalFeatures.title'),
      points: [
        t('whyChoose.additionalFeatures.point1'),
        t('whyChoose.additionalFeatures.point2'),
        t('whyChoose.additionalFeatures.point3')
      ]
    }
  ];

  const styles = `
    <style>
      .category-nav {
        width: 95%;
        margin: 0 auto;
        border-radius: 20px;
        background-color: transparent;
        overflow: hidden;
        padding: 10px;
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        gap: 20px;
      }

      .category-nav .nav-item {
        flex: 0 0 auto;
        margin: 0 10px;
      }

      .category-nav-link {
        border-radius: 25px !important;
        padding: 15px 30px !important;
        font-size: 16px !important;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff !important;
        background-color: transparent !important;
        transition: all 0.3s ease;
        border: 1px solid rgba(255, 255, 255, 0.5);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon-wrapper {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #ffffff !important;
      }

      .white-icon {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .icon-wrapper svg,
      .icon-wrapper svg path {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .why-choose-card .icon-wrapper {
        color: #ffffff !important;
      }

      .why-choose-card .icon-wrapper svg,
      .why-choose-card .icon-wrapper svg path {
        color: #ffffff !important;
        fill: #ffffff !important;
      }

      .why-choose-card li:before {
        content: "→";
        position: absolute;
        left: 0;
        color: #ffffff !important;
      }

      /* Rest of your existing styles... */

      @media (max-width: 768px) {
        .category-nav {
          flex-direction: row !important;
          flex-wrap: wrap;
          gap: 10px;
        }
        
        .category-nav-link {
          width: auto;
          padding: 12px 20px !important;
        }
      }

      .nav.nav-pills {
        background-color: transparent !important;
      }
    </style>
  `;

  return (
    <section className="project" id="why-choose">
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>{t('whyChoose.title')}</h2>
                  <p>{t('whyChoose.subtitle')}</p>
                  
                  {/* Category Selection Buttons */}
                  <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                    <Nav.Item>
                      <Nav.Link 
                        className={`category-nav-link ${selectedCategory === "forPatients" ? 'active' : ''}`}
                        onClick={() => setSelectedCategory("forPatients")}
                      >
                        <span>{t('whyChoose.forPatients')}</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link 
                        className={`category-nav-link ${selectedCategory === "forDoctors" ? 'active' : ''}`}
                        onClick={() => setSelectedCategory("forDoctors")}
                        data-category="forDoctors"
                      >
                        <span>{t('whyChoose.forProfessionals')}</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  
                  {/* Content for Patients */}
                  {selectedCategory === "forPatients" && (
                    <div className="category-content">
                      <Row>
                        {patientBenefits.map((benefit, index) => (
                          <Col md={6} className="mb-4" key={index}>
                            <div className="why-choose-card">
                              <div className="icon-wrapper white-icon">
                                <FontAwesomeIcon icon={benefit.icon} className="white-icon" />
                              </div>
                              <h3>{benefit.title}</h3>
                              <ul>
                                {benefit.points.map((point, i) => (
                                  <li key={i}>{point}</li>
                                ))}
                              </ul>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                  
                  {/* Content for Doctors */}
                  {selectedCategory === "forDoctors" && (
                    <div className="category-content">
                      <Row>
                        {professionalBenefits.map((benefit, index) => (
                          <Col md={6} className="mb-4" key={index}>
                            <div className="why-choose-card">
                              <div className="icon-wrapper white-icon">
                                <FontAwesomeIcon icon={benefit.icon} className="white-icon" />
                              </div>
                              <h3>{benefit.title}</h3>
                              <ul>
                                {benefit.points.map((point, i) => (
                                  <li key={i}>{point}</li>
                                ))}
                              </ul>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </div>}
              </TrackVisibility>
            </div>
          </Col>
        </Row>
      </Container>
      <div dangerouslySetInnerHTML={{ __html: styles }} />
    </section>
  );
}; 