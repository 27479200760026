import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { FaLinkedin } from 'react-icons/fa';
import { SiCrunchbase } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import './LanguageSwitcher.css';
import './NavBar.css';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img 
              src="/favicon.png" 
              alt="Logo" 
              className="navbar-logo" 
              style={{ 
                width: '100px', 
                height: '100px',
                display: 'inline-block',
                objectFit: 'contain'
              }} 
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Desktop Menu */}
            <Nav className="desktop-menu ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>{t('navbar.home')}</Nav.Link>
              <Nav.Link href="#why-choose" className={activeLink === 'why-choose' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('why-choose')}>{t('navbar.whyChoose')}</Nav.Link>
              <Nav.Link href="#how-it-works" className={activeLink === 'how-it-works' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('how-it-works')}>{t('navbar.howItWorks')}</Nav.Link>
              <Nav.Link href="#who-are-we" className={activeLink === 'who-are-we' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('who-are-we')}>{t('navbar.whoAreWe')}</Nav.Link>
              <Nav.Link href="#roadmap" className={activeLink === 'roadmap' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('roadmap')}>{t('navbar.roadmap')}</Nav.Link>
            </Nav>
            <span className="navbar-text desktop-menu">
              <div className="social-icon-container">
                <a href="https://www.linkedin.com/company/drmedkit/" target="_blank" rel="noopener noreferrer" className="social-icon-small">
                  <FaLinkedin />
                </a>
                <a href="https://www.crunchbase.com/organization/dr-medkit" target="_blank" rel="noopener noreferrer" className="social-icon-small">
                  <SiCrunchbase />
                </a>
              </div>
              <HashLink to='#video'>
                <button className="vvd mvp-btn"><span>{t('navbar.checkMvp')}</span></button>
              </HashLink>
              <HashLink to='#connect'>
                <button className="vvd"><span>{t('navbar.connect')}</span></button>
              </HashLink>
              <LanguageSwitcher />
            </span>

            {/* Mobile Menu */}
            <div className="mobile-menu-wrapper">
              <div className="mobile-language">
                <LanguageSwitcher />
              </div>
              <Nav className="mobile-nav-links">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>{t('navbar.home')}</Nav.Link>
                <Nav.Link href="#why-choose" className={activeLink === 'why-choose' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('why-choose')}>{t('navbar.whyChoose')}</Nav.Link>
                <Nav.Link href="#how-it-works" className={activeLink === 'how-it-works' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('how-it-works')}>{t('navbar.howItWorks')}</Nav.Link>
                <Nav.Link href="#who-are-we" className={activeLink === 'who-are-we' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('who-are-we')}>{t('navbar.whoAreWe')}</Nav.Link>
                <Nav.Link href="#roadmap" className={activeLink === 'roadmap' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('roadmap')}>{t('navbar.roadmap')}</Nav.Link>
              </Nav>
              <div className="mobile-social-icons">
                <div className="social-icon-container">
                  <a href="https://www.linkedin.com/company/drmedkit/" target="_blank" rel="noopener noreferrer" className="social-icon-small">
                    <FaLinkedin />
                  </a>
                  <a href="https://www.crunchbase.com/organization/dr-medkit" target="_blank" rel="noopener noreferrer" className="social-icon-small">
                    <SiCrunchbase />
                  </a>
                </div>
              </div>
              <span className="navbar-text">
                <HashLink to='#video'>
                  <button className="vvd mvp-btn"><span>{t('navbar.checkMvp')}</span></button>
                </HashLink>
                <HashLink to='#connect'>
                  <button className="vvd"><span>{t('navbar.connect')}</span></button>
                </HashLink>
              </span>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
}
