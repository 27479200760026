import { useTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const { t } = useTranslation();
  
  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>{t('skills.title')}</h2>
                        <p>{t('skills.description')}</p>
                        <div className="feature-grid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Short descriptive text without using the word 'image'" />
    </section>
  )
}
