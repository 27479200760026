import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const FloatingMvpButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth <= 991);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isVisible) return null;

  return (
    <Router>
      <HashLink to='#video'>
        <button className="floating-mvp-button">
          {t('floatingMvpButton.checkOurMVP')}
        </button>
      </HashLink>
    </Router>
  );
}; 