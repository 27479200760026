import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faRobot,
  faUserDoctor,
  faKitMedical,
  faUsers,
  faIdCard,
  faCalendarCheck,
  faClipboardList,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import './HowItWorks.css';
import { useTranslation } from 'react-i18next';

export const HowItWorks = () => {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("forPatients");

  // Reset selected category when language changes to ensure proper rendering
  useEffect(() => {
    setSelectedCategory("forPatients");
  }, [i18n.language]);

  // Define steps for patients
  const patientSteps = [
    {
      icon: faUserPlus,
      title: t('howItWorks.patients.step1.title'),
      description: t('howItWorks.patients.step1.description')
    },
    {
      icon: faRobot,
      title: t('howItWorks.patients.step2.title'),
      description: t('howItWorks.patients.step2.description')
    },
    {
      icon: faUserDoctor,
      title: t('howItWorks.patients.step3.title'),
      description: t('howItWorks.patients.step3.description')
    },
    {
      icon: faKitMedical,
      title: t('howItWorks.patients.step4.title'),
      description: t('howItWorks.patients.step4.description')
    },
    {
      icon: faUsers,
      title: t('howItWorks.patients.step5.title'),
      description: t('howItWorks.patients.step5.description')
    }
  ];

  // Define steps for professionals
  const professionalSteps = [
    {
      icon: faIdCard,
      title: t('howItWorks.professionals.step1.title'),
      description: t('howItWorks.professionals.step1.description')
    },
    {
      icon: faCalendarCheck,
      title: t('howItWorks.professionals.step2.title'),
      description: t('howItWorks.professionals.step2.description')
    },
    {
      icon: faClipboardList,
      title: t('howItWorks.professionals.step3.title'),
      description: t('howItWorks.professionals.step3.description')
    },
    {
      icon: faVideo,
      title: t('howItWorks.professionals.step4.title'),
      description: t('howItWorks.professionals.step4.description')
    }
  ];

  return (
    <section className="project" id="how-it-works">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>{t('howItWorks.title')}</h2>
              <p>{t('howItWorks.subtitle')}</p>
              
              {/* Category Selection Buttons */}
              <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                <div className="nav-item">
                  <Nav.Link 
                    className={`category-nav-link ${selectedCategory === "forPatients" ? 'active' : ''}`}
                    onClick={() => setSelectedCategory("forPatients")}
                  >
                    {t('howItWorks.forPatients')}
                  </Nav.Link>
                </div>
                <div className="nav-item">
                  <Nav.Link 
                    className={`category-nav-link ${selectedCategory === "forProfessionals" ? 'active' : ''}`}
                    onClick={() => setSelectedCategory("forProfessionals")}
                    data-category="forDoctors"
                  >
                    {t('howItWorks.forProfessionals')}
                  </Nav.Link>
                </div>
              </Nav>
              
              {/* Content for Patients */}
              {selectedCategory === "forPatients" && (
                <div className="how-it-works-container">
                  {patientSteps.map((step, index) => (
                    <div className="how-it-works-item" key={index}>
                      <div className="icon-box">
                        <FontAwesomeIcon icon={step.icon} className="icon" />
                      </div>
                      <div className="content">
                        <h3>{step.title}</h3>
                        <p>{step.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              
              {/* Content for Professionals */}
              {selectedCategory === "forProfessionals" && (
                <div className="how-it-works-container">
                  {professionalSteps.map((step, index) => (
                    <div className="how-it-works-item professional" key={index}>
                      <div className="icon-box">
                        <FontAwesomeIcon icon={step.icon} className="icon" />
                      </div>
                      <div className="content">
                        <h3>{step.title}</h3>
                        <p>{step.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};