import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const SEO = ({ 
  title, 
  description, 
  keywords, 
  canonicalUrl = 'https://drmedkit.com',
  imageUrl = '/logo192.png'
}) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en' || i18n.language === 'en-US';

  // Enhanced default titles and descriptions with targeted keywords
  const defaultTitle = isEnglish 
    ? "Dr.Medkit - AI Telemedicine & Digital Health Solutions | Best Virtual Healthcare Platform"
    : "Dr.Medkit - Yapay Zeka Telesağlık & Dijital Sağlık Çözümleri | En İyi Sanal Sağlık Platformu";

  const defaultDescription = isEnglish
    ? "Experience advanced telemedicine with Dr.Medkit's AI-powered symptom checker. Get instant virtual healthcare consultations and connect with verified doctors online. The best telemedicine app for AI-assisted diagnosis and remote healthcare."
    : "Dr.Medkit'in yapay zeka destekli semptom kontrolü ile gelişmiş telesağlık deneyimi yaşayın. Anında sanal sağlık konsültasyonları alın ve çevrimiçi olarak doğrulanmış doktorlarla bağlantı kurun. Türkiye'nin en iyi telesağlık ve teletıp uygulaması.";

  // Enhanced keyword list with detailed search queries
  const defaultKeywords = isEnglish
    ? "telemedicine, AI symptom checker, virtual healthcare, best telemedicine app, AI healthcare platform, online doctor consultation, digital health solutions, remote healthcare services, medical AI diagnosis, best AI telemedicine app, telehealth services, virtual doctor visit, AI medical assistant"
    : "telesağlık, teletıp, yapay zeka semptom kontrolü, sanal sağlık hizmeti, çevrimiçi doktor, dijital sağlık, yapay zeka sağlık, online doktor danışmanlığı, en iyi türkiye telesağlık uygulaması, türkiye telesağlık, en iyi türkiye teletıp uygulaması, türkiye teletıp, yapay zeka tıbbi tanı, uzaktan sağlık hizmetleri";

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://drmedkit.com/#organization",
        "name": "Dr.Medkit",
        "url": "https://drmedkit.com",
        "logo": {
          "@type": "ImageObject",
          "@id": "https://drmedkit.com/#logo",
          "url": "https://drmedkit.com/logo192.png",
          "contentUrl": "https://drmedkit.com/logo192.png",
          "caption": "Dr.Medkit Logo"
        },
        "description": isEnglish 
          ? "Provider of AI-powered telemedicine and digital health solutions, connecting patients with verified doctors through a virtual healthcare platform." 
          : "Yapay zeka destekli telesağlık ve dijital sağlık çözümleri sağlayıcısı, hastaları sanal sağlık platformu aracılığıyla doğrulanmış doktorlarla buluşturur."
      },
      {
        "@type": "WebSite",
        "@id": "https://drmedkit.com/#website",
        "url": "https://drmedkit.com",
        "name": "Dr.Medkit",
        "publisher": {
          "@id": "https://drmedkit.com/#organization"
        },
        "inLanguage": isEnglish ? "en" : "tr",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://drmedkit.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      },
      {
        "@type": "MedicalOrganization",
        "@id": "https://drmedkit.com/#medical",
        "name": "Dr.Medkit",
        "url": "https://drmedkit.com",
        "description": defaultDescription,
        "medicalSpecialty": [
          "Telemedicine",
          "Digital Health",
          "AI-Powered Healthcare"
        ],
        "availableLanguage": ["en", "tr"],
        "sameAs": [
          "https://www.linkedin.com/company/drmedkit/",
          "https://www.crunchbase.com/organization/dr-medkit"
        ],
        "areaServed": {
          "@type": "Country",
          "name": isEnglish ? "Global" : "Türkiye"
        }
      },
      {
        "@type": "SoftwareApplication",
        "applicationCategory": "HealthApplication",
        "name": isEnglish ? "Dr.Medkit AI Symptom Checker" : "Dr.Medkit Yapay Zeka Semptom Kontrolü",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "USD"
        },
        "description": isEnglish 
          ? "An AI-powered telemedicine platform that provides symptom checking and connects patients with doctors." 
          : "Semptom kontrolü sağlayan ve hastaları doktorlarla buluşturan yapay zeka destekli bir telesağlık platformu."
      }
    ]
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <meta name="language" content={i18n.language} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={`${canonicalUrl}${imageUrl}`} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="Dr.Medkit" />
      <meta property="og:locale" content={isEnglish ? "en_US" : "tr_TR"} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={`${canonicalUrl}${imageUrl}`} />

      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      {/* Location meta tags */}
      {!isEnglish && (
        <>
          <meta name="geo.region" content="TR" />
          <meta name="geo.placename" content="Türkiye" />
        </>
      )}
      
      {/* Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
}; 