import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
import backgroundImage from "../assets/img/3.png"; // Import the background image
import { useTranslation } from 'react-i18next';

console.log("Background Image:", backgroundImage);

// eslint-disable-next-line no-unused-vars
const someUnusedVar = 'for future use';

export const Projects = () => {
  const { t, i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("For Patients");
  
  // Reset selected category when language changes to ensure proper rendering
  useEffect(() => {
    setSelectedCategory("For Patients");
  }, [i18n.language]);
  
  // Define projects data structure using translations
  const projects = [
    {
      category: "For Patients",
      features: [
        {
          title: t('projects.patients.feature1.title'),
          description: t('projects.patients.feature1.description'),
          benefits: [
            t('projects.patients.feature1.benefit1'),
            t('projects.patients.feature1.benefit2')
          ]
        },
        {
          title: t('projects.patients.feature2.title'),
          description: t('projects.patients.feature2.description'),
          benefits: [
            t('projects.patients.feature2.benefit1'),
            t('projects.patients.feature2.benefit2'),
            t('projects.patients.feature2.benefit3')
          ]
        },
        {
          title: t('projects.patients.feature3.title'),
          description: t('projects.patients.feature3.description'),
          benefits: [
            t('projects.patients.feature3.benefit1'),
            t('projects.patients.feature3.benefit2'),
            t('projects.patients.feature3.benefit3')
          ]
        },
        {
          title: t('projects.patients.feature4.title'),
          description: t('projects.patients.feature4.description'),
          benefits: [
            t('projects.patients.feature4.benefit1'),
            t('projects.patients.feature4.benefit2'),
            t('projects.patients.feature4.benefit3')
          ]
        },
        {
          title: t('projects.patients.feature5.title'),
          description: t('projects.patients.feature5.description'),
          benefits: [
            t('projects.patients.feature5.benefit1'),
            t('projects.patients.feature5.benefit2'),
            t('projects.patients.feature5.benefit3')
          ]
        }
      ]
    },
    {
      category: "For Professionals",
      features: [
        {
          title: t('projects.professionals.feature1.title'),
          description: t('projects.professionals.feature1.description'),
          benefits: [
            t('projects.professionals.feature1.benefit1'),
            t('projects.professionals.feature1.benefit2'),
            t('projects.professionals.feature1.benefit3')
          ]
        },
        {
          title: t('projects.professionals.feature2.title'),
          description: t('projects.professionals.feature2.description'),
          benefits: [
            t('projects.professionals.feature2.benefit1'),
            t('projects.professionals.feature2.benefit2'),
            t('projects.professionals.feature2.benefit3')
          ]
        },
        {
          title: t('projects.professionals.feature3.title'),
          description: t('projects.professionals.feature3.description'),
          benefits: [
            t('projects.professionals.feature3.benefit1'),
            t('projects.professionals.feature3.benefit2'),
            t('projects.professionals.feature3.benefit3')
          ]
        },
        {
          title: t('projects.professionals.feature4.title'),
          description: t('projects.professionals.feature4.description'),
          benefits: [
            t('projects.professionals.feature4.benefit1'),
            t('projects.professionals.feature4.benefit2'),
            t('projects.professionals.feature4.benefit3')
          ]
        },
        {
          title: t('projects.professionals.feature5.title'),
          description: t('projects.professionals.feature5.description'),
          benefits: [
            t('projects.professionals.feature5.benefit1'),
            t('projects.professionals.feature5.benefit2'),
            t('projects.professionals.feature5.benefit3')
          ]
        }
      ]
    }
  ];

  // Get features based on selected category
  const currentFeatures = projects.find(p => p.category === selectedCategory)?.features || [];

  return (
    <section className="project" id="projects" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/2.png)`}}>
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
                <div>
                  <h2>{t('projects.title')}</h2>
                  <p>{t('projects.subtitle')}</p>
                  
                  {/* Category Selection Buttons */}
                  <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                    {projects.map((project, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link 
                          className={`category-nav-link ${selectedCategory === project.category ? 'active' : ''}`}
                          onClick={() => setSelectedCategory(project.category)}
                          data-category={project.category === "For Professionals" ? "forDoctors" : ""}
                        >
                          <span>{project.category === "For Patients" ? t('projects.forPatients') : t('projects.forProfessionals')}</span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>

                  <Tab.Container id="projects-tabs" defaultActiveKey="0" data-category={selectedCategory === "For Professionals" ? "forDoctors" : ""}>
                    <div className="feature-list-background">
                      <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                        {currentFeatures.map((feature, index) => (
                          <Nav.Item key={index}>
                            <Nav.Link 
                              eventKey={index.toString()} 
                              className="custom-nav-link"
                              data-category={selectedCategory === "For Professionals" ? "forDoctors" : ""}
                            >
                              <span>{feature.title}</span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content id="slideInUp">
                        {currentFeatures.map((feature, index) => (
                          <Tab.Pane eventKey={index.toString()} key={index}>
                            <div className="project-description">
                              <h4>{feature.title}</h4>
                              <p>{feature.description}</p>
                              <div className="benefits-section">
                                <ul>
                                  {feature.benefits.map((benefit, idx) => (
                                    <li key={idx}>{benefit}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
