import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { WhoAreWe } from "./components/WhoAreWe";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { WhyChoose } from "./components/WhyChoose";
import { HowItWorks } from "./components/HowItWorks";
import backgroundImage from './assets/img/5.png';
import { ProjectRoadmap } from './components/ProjectRoadmap';
import { VideoSection } from "./components/VideoSection";
import { FloatingMvpButton } from './components/FloatingMvpButton';
import { SEO } from './components/SEO';

function App() {
  
  return (
    <HelmetProvider>
      <div className="App" style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
      }}>
        <SEO />
        <NavBar />
        <Banner />
        <Skills />
        <WhyChoose />
        <Projects />
        <HowItWorks />
        <WhoAreWe />
        <ProjectRoadmap />
        <VideoSection />
        <Contact />
        <Footer />
        <FloatingMvpButton />
      </div>
    </HelmetProvider>
  );
}

export default App;
